import hljs from 'highlight.js';
import renderMathInElement from 'katex/dist/contrib/auto-render';

hljs.highlightAll();
renderMathInElement(document.body, {
  delimiters: [
    { left: '$$', right: '$$', display: true },
    { left: '$', right: '$', display: false },
    { left: '\\(', right: '\\)', display: false },
    { left: '\\[', right: '\\]', display: true },
  ],
});

import '@fortawesome/fontawesome-free/js/all';

import ClipboardJS from 'clipboard';

new ClipboardJS('#discord-btn');
